import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const AuthorisedFilesFolder = () => {
  return (
    <Layout>
      <Seo
        title="Authorised Files Folder"
        description={`Manage authorized files and signatures efficiently with our user-friendly platform. View, sign, and track document statuses seamlessly with your accountant.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`authorised-files-folder`} />
    </Layout>
  )
}

export default AuthorisedFilesFolder
